import Admin from "./Pages/Admin/App"

function App() {
  return (
      <Admin />
      // <Alumni />
      // <Public />÷\
  );
}
export default App;
